.header {
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;

  section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
      width: 49%;

      h1 {
        margin-bottom: 25px;
        font-weight: bold;
        font-size: 48px;
        line-height: 56px;
      }

      p {
        margin-bottom: 45px;
        font-weight: 300;
        color: #7d7987;
        font-size: 21px;
        line-height: 32px;
      }
    }

    .imgContainer {
      width: 49%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header section {
    flex-direction: column;
    gap: 24px;

    .content,
    .imgContainer {
      width: 100%;
      max-width: 600px;
    }
  }
}

@media screen and (max-width: 640px) {
  .header section {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 36px;
        text-align: center;
        line-height: 44px;
      }

      p {
        text-align: center;
      }
    }
  }
}
