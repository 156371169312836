.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;

  .sectionHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    text-align: center;

    h3 {
      font-size: 36px;
      line-height: 56px;
      font-weight: bold;
    }

    .divider {
      width: 56px;
      height: 2px;
      background: #000;
    }

    p {
      font-size: 18px;
      line-height: 30px;
      color: #7d7987;
    }
  }

  .cardContainer {
    margin: 80px 0 70px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 36px 32px;
    justify-content: center;
  }
}
