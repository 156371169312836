.serviceCard {
  width: 325px;
  padding: 40px 32px;
  border: 1px solid #eee;
  border-radius: 20px;
  transition: all 0.25s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  .textContainer {
    margin-top: 25px;

    h4 {
      font-size: 24px;
      line-height: 56px;
      font-weight: bold;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      color: #7d7987;
    }
  }
}

@media screen and (max-width: 500px) {
  .serviceCard {
    padding: 36px 24px;
  }
}
