.logo {
  display: flex;
  align-items: center;
  gap: 12px;

  .logoTitle {
    font-size: 24px;
    color: #233348;
    font-weight: bold;
  }
}
