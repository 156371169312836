.navBtn {
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border: 1px solid #f9fafb;
  border-radius: 6px;
  cursor: pointer;

  .icon {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: #f3f4f6;
  }
}
