.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1140px;

  .container {
    display: flex;
    justify-content: space-between;
  }

  .menu {
    display: flex;
    gap: 10px 32px;
    list-style: none;
    color: #1f1534;

    li {
      font-size: 18px;

      &.active {
        color: #1f1534;
        font-weight: bold;
      }

      &:hover {
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .navbar {
    flex-direction: column;
    align-items: start;

    .container {
      width: 100%;
    }

    .menu {
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-height: 0;
      padding: 0;
      border-radius: 8px;
      background: #f9fafb;
      overflow: hidden;
      transition: all 0.25s;
    }

    .expand {
      max-height: 500px;
      margin-top: 10px;
      padding: 12px 0;
      border: 1px solid #f3f4f6;
    }
  }
}
