.heroSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;

  .content {
    width: 45%;

    h1 {
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
    }

    .divider {
      margin: 24px 0 30px 0;
      width: 56px;
      height: 2px;
      background: #000;
    }

    p {
      margin-bottom: 45px;
      font-weight: 300;
      color: #7d7987;
      font-size: 21px;
      line-height: 32px;
    }
  }

  .imgContainer {
    width: 45%;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.flip {
  gap: 72px;

  .content {
    order: 1;
  }
}

@media screen and (max-width: 768px) {
  .heroSection {
    flex-direction: column;
    gap: 32px;

    .content,
    .imgContainer {
      width: 100%;
      max-width: 600px;
    }
  }
}

@media screen and (max-width: 640px) {
  .heroSection {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 36px;
        text-align: center;
        line-height: 44px;
      }

      p {
        text-align: center;
      }
    }
  }
}
