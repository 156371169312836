* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  display: block;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  margin-top: 36px;
  padding: 0 1rem;
  overflow-x: hidden;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  main {
    display: flex;
    flex-direction: column;
    gap: 200px;
    margin-top: 144px;
    margin-bottom: 44px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  body {
    margin-top: 16px;
  }
}
