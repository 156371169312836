.button {
  display: flex;
  align-items: center;
  gap: 14px;
  box-sizing: border-box;
  padding: 16px 40px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 55px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.25s;
}

.primaryBtn {
  background: #458ff6;
  color: #fff;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.97);
  }
}

.secondaryBtn {
  background: #fff;
  color: #458ff6;
  border-color: #458ff6;

  &:hover {
    outline: 1px solid #458ff6;
  }

  &:active {
    transform: scale(0.97);
  }
}
